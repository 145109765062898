import * as React from "react";
import { GlobalStyle } from "@src/GlobalStyle";
import { Link } from "gatsby";
import Navbar from "@components/Navbar/Navbar";
import Footer from "@components/Footer/Footer";
import MenuNav from "@components/MenuNav/MenuNav";
import SiteStructure from "@datasources/SiteStructure";
import { Helmet } from "react-helmet";
import Threestages from "@components/ThreeStages/Threestages";
import BlogPage from "@components/BlogPage/BlogPage";


// markup
const IndexPage = () => {



  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
        <title>QuEST LAC : otras actividades</title>
        <meta name="description" content="Actividades de la Red QuEST en LatinoAmerica y el Caribe" />
        <meta name="keywords" content="QUEST,NETWORK,Latinoamerica,Caribe,PERU,UPCH,ARGENTINA,HARVARD,Cursos" />
      </Helmet>
      <GlobalStyle />
      <Navbar />
      <Threestages

        leftNav={<><MenuNav
          title={SiteStructure[3]["title"]}
          AnyList={SiteStructure[3]["links"]}
        />
        </>}

        content={(<>
          {<BlogPage
            title={"Other activities"}
            content={(<>
              <p>Here, new activities will be placed</p>
            </>)}
          />}
        </>)}
      />

      <Footer />
    </>

  )
};

export default IndexPage
