const SiteStructure = [
    {
        "title":"About",
        "links":[
            {
                "title":"What is QuEST",
                "link":"/acerca/que-es"
            },
            {
                "title":"Objectives",
                "link":"/acerca/objetivos",
                "submenu":{
                    "title":"Objectives",
                    "links":[
                        {
                            "title":"Consolidate as the Latin American Chapter for the QuEST network",
                            "link":"/acerca/objetivos/objetivo1"
                        },
                        {
                            "title":"Promote the improvement of skill in research about Health Systems in LAC region and the members of the network.",
                            "link":"/acerca/objetivos/objetivo2"
                        },
                        {
                            "title":"Research and Improvement in LAC region to inform the local policies makers.",
                            "link":"/acerca/objetivos/objetivo3"
                        },
                    ]
                },
            },
            {
                "title":"Structure",
                "link":"/acerca/estructura"
            },
            {
                "title":"Funding",
                "link":"/acerca/financiamiento"
            },
            {
                "title":"Global Alliances",
                "link":"/acerca/partners"
            },
        ]
    },
    {
        "title":"Health Quality",
        "links":[
            {
                "title":"Definitions",
                "link":"/calidadensalud/definiciones"
            },
            {
                "title":"Importance",
                "link":"/calidadensalud/importancia"
            },
        ],
    },
    {
        "title":"Projects",
        "links":[
            {
                "title":"On going Projects",
                "link":"/proyectos/enmarcha"
            },
            {
                "title":"Future Projects",
                "link":"/proyectos/futuros"
            },
        ]
    },
    {
        "title":"Activities",
        "links":[
        {
            "title":"Webinars",
            "link":"/actividades/webinars"
        },
        {
            "title":"Cshort Courses",
            "link":"/actividades/cursos-cortos"
        },
        {
            "title":"Publications",
            "link":"/actividades/publicaciones"
        },
        {
            "title":"Other activities",
            "link":"/actividades/otros"
        },
    ]
}    
]

export default SiteStructure;